<template>
<v-row>
  <v-col cols="12" sm="6">
    <search-box :disabled="selecionouClienteAvulso"
        label="Cliente"
        popup-label="Selecione um Cliente"
        :id.sync="value.idCliente"
        :nome.sync="value.nomeCliente"
        :value="cliente"
        :loader-fn="loaderClientes"
        item-key="id"
        item-text="nome">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhum Cliente encontrado
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" sm="6">
    <v-text-field :disabled="parseInt(value.idCliente) > 0 && !selecionouClienteAvulso"
        label="* Cliente Avulso" dense type="text" v-model.trim="value.clienteAvulso" />
  </v-col>
  <v-col cols="12" sm="4">
    <search-box
        :hidden-clear="true"
        :emit-all-properties="true"
        label="* Competição"
        popup-label="Selecione uma Competição Ativa"
        :id.sync="value.idCompCompeticao"
        :descricao.sync="value.descInscricaoCompeticao"
        :idCompEvento.sync="value.idCompEvento"
        :provas.sync="value.provasCompeticao"
        :value="competicao"
        :loader-fn="loaderCompeticoesAtivas"
        item-key="id"
        item-text="descricao"
        :rules="[
              this.$validators.notNullOrUndefined
          ]">
      <template v-slot:empty>
        <p class="ma-2">
          Nenhuma Competição Ativa encontrada
        </p>
      </template>
    </search-box>
  </v-col>
  <v-col cols="12" sm="4">
    <v-text-field dense
        label="* Valor Insc."
        prefix="R$"
        :rules="[
            this.$validators.number.required,
            v => this.$validators.number.greaterThanOrEquals(v, 0)
        ]"
        v-model="value.valorInscricao" type="number"/>
  </v-col>
  <v-col cols="12" sm="4">
    <lookup-dominio label="* Status"
                    v-model="value.status"
                    :value="statusAtivo"
                    :type="lookups.status"
                    :hidden-clear="false"/>
  </v-col>
  <v-col cols="12">
    <!-- {{value.provasCompeticao ? value.provasCompeticao.provas : []}} -->
    <v-row v-show="parseInt(value.idCompEvento) === 1">
      <v-col cols="12" sm="6">
          <strong>WTP RANKING</strong> <v-icon @click="limpaProva(1)" >mdi-reload</v-icon>
          <v-radio-group 
            v-model="prova1"
            name="prova1"
            column >
            <v-radio label="Grand Slam" color="red" value="1"></v-radio>
            <v-radio label="Winner 1000" color="red" value="2"></v-radio>
            <v-radio label="Winner 500" color="red" value="3"></v-radio>
            <v-radio label="Winner 250" color="red" value="4"></v-radio>
          </v-radio-group>
      </v-col>
      <v-col cols="12" sm="6">
          <strong>SÊNIOR</strong> <v-icon @click="limpaProva(2)" >mdi-reload</v-icon>
          <v-radio-group 
            v-model="prova2"
            name="prova2"
            column >
            <v-radio label="Sênior A" color="red" value="1"></v-radio>
            <v-radio label="Sênior B" color="red" value="2"></v-radio>
            <v-radio label="Sênior C" color="red" value="3"></v-radio>
          </v-radio-group>
      </v-col>
      <!-- v-text-field v-show="true" v-model="value.provas"></v-text-field -->
    </v-row>
  </v-col>  
</v-row>
</template>

<script>
import SearchBox from "../common/SearchBox";
import {findAllCompeticoesAtivas} from "../../../api/competicoes";
import {findAll as findAllClientes} from "../../../api/clientes";
import LookupDominio from "../common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";
import moment from 'moment';

export default {
  name: "InscricaoForm",
  components: {LookupDominio, SearchBox},
  props: {
    value: {
      type: Object
    }
  },
  data () {
    return {
      lookups: {
        status: ItemDeDominio.STATUS_PADRAO
      },
      prova1: null,
      tituloProvaWTP: "WTP RANKING",
      provaWTP: [
        {id: "1", descricao: "Grand Slam"},
        {id: "2", descricao: "Winner 1000"},
        {id: "3", descricao: "Winner 500"},
        {id: "4", descricao: "Winner 250"},
      ],
      valor1: 150.00,
      valor2: 200.00,      
      prova2: null,
      tituloProvaSenior: "SÊNIOR",
      provaSenior: [
        {id: "1", descricao: "Sênior A"},
        {id: "2", descricao: "Sênior B"},
        {id: "3", descricao: "Sênior C"},
      ]
    }
  },
  computed: {
    selecionouClienteAvulso() {
      return this.value.clienteAvulso != null && this.value.clienteAvulso.trim() !== '';
    },
    cliente() {
      if (this.value.idCliente == null) return null;
      return {
        id: this.value.idCliente,
        nome: this.value.nomeCliente
      }
    },
    statusAtivo() {
      if (this.value.status == null) {
        this.value.status = "A"
      };
    },
    competicao() {
      if(this.value.idCompCompeticao == null) return null;
      return {
        id: this.value.idCompCompeticao,
        descricao: this.value.descInscricaoCompeticao,
        idCompEvento: this.value.idCompEvento,
        provas: this.value.provas,
      }
    },
  },
  watch: {
    'prova1': {
      handler(newValue, oldValue) {
        this.condiguraProvasEscolhidasESetaValor();
      }
    },
    'prova2': {
      handler(newValue, oldValue) {
        this.condiguraProvasEscolhidasESetaValor();
      }
    },
    'value.idCompCompeticao': {
      handler(newValue, oldValue) {
        this.condiguraProvasEscolhidasESetaValor();
      }
    },
    'value.idCliente': {
      handler(v) {
        if(v >= 0) {
          this.value.clienteAvulso = '';
        }
      }
    },
  },  
  methods: {
    loaderClientes(page, search) {
      return findAllClientes(page, {
        nome: search
      })
    },
    loaderCompeticoesAtivas(page, search) {
      return findAllCompeticoesAtivas(page, {
        descricao: search
      })
    },
    condiguraProvasEscolhidasESetaValor() {
      let prova = "";
      let contador = 0;
      let idCompEvento = parseInt(this.value.idCompEvento);
      if (idCompEvento == 1){
        if (parseInt(this.prova1) > 0) {
          let obj1 = this.provaWTP.find(option => option.id === this.prova1);
          prova += '1|'+this.tituloProvaWTP+'$'+obj1.id+"|"+obj1.descricao;
          contador++;
        }
        if (parseInt(this.prova2) > 0) {
          let obj2 = this.provaSenior.find(option => option.id === this.prova2);
          prova += (prova !== "" ? "#" : "" )+'2|'+this.tituloProvaSenior+'$'+obj2.id+"|"+obj2.descricao;
          contador++
        }
        if (contador > 1) this.value.valorInscricao = this.valor2;
        else this.value.valorInscricao = this.valor1;
        this.value.provasCad = prova;
      } else {
        this.value.valorInscricao = parseFloat(this.value.provasCompeticao.valor1);
        this.value.provasCad = "";
      }
    }, 
    limpaProva(id) {
      if (id==1) this.prova1 = null;
      else this.prova2 = null;
    }
  },
}
</script>

<style scoped>

</style>