var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('search-box',{attrs:{"disabled":_vm.selecionouClienteAvulso,"label":"Cliente","popup-label":"Selecione um Cliente","id":_vm.value.idCliente,"nome":_vm.value.nomeCliente,"value":_vm.cliente,"loader-fn":_vm.loaderClientes,"item-key":"id","item-text":"nome"},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCliente", $event)},"update:nome":function($event){return _vm.$set(_vm.value, "nomeCliente", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Cliente encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"disabled":parseInt(_vm.value.idCliente) > 0 && !_vm.selecionouClienteAvulso,"label":"* Cliente Avulso","dense":"","type":"text"},model:{value:(_vm.value.clienteAvulso),callback:function ($$v) {_vm.$set(_vm.value, "clienteAvulso", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.clienteAvulso"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('search-box',{attrs:{"hidden-clear":true,"emit-all-properties":true,"label":"* Competição","popup-label":"Selecione uma Competição Ativa","id":_vm.value.idCompCompeticao,"descricao":_vm.value.descInscricaoCompeticao,"idCompEvento":_vm.value.idCompEvento,"provas":_vm.value.provasCompeticao,"value":_vm.competicao,"loader-fn":_vm.loaderCompeticoesAtivas,"item-key":"id","item-text":"descricao","rules":[
              this.$validators.notNullOrUndefined
          ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idCompCompeticao", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descInscricaoCompeticao", $event)},"update:idCompEvento":function($event){return _vm.$set(_vm.value, "idCompEvento", $event)},"update:id-comp-evento":function($event){return _vm.$set(_vm.value, "idCompEvento", $event)},"update:provas":function($event){return _vm.$set(_vm.value, "provasCompeticao", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhuma Competição Ativa encontrada ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":"* Valor Insc.","prefix":"R$","rules":[
            this.$validators.number.required,
            function (v) { return this$1.$validators.number.greaterThanOrEquals(v, 0); }
        ],"type":"number"},model:{value:(_vm.value.valorInscricao),callback:function ($$v) {_vm.$set(_vm.value, "valorInscricao", $$v)},expression:"value.valorInscricao"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('lookup-dominio',{attrs:{"label":"* Status","value":_vm.statusAtivo,"type":_vm.lookups.status,"hidden-clear":false},model:{value:(_vm.value.status),callback:function ($$v) {_vm.$set(_vm.value, "status", $$v)},expression:"value.status"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(parseInt(_vm.value.idCompEvento) === 1),expression:"parseInt(value.idCompEvento) === 1"}]},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('strong',[_vm._v("WTP RANKING")]),_vm._v(" "),_c('v-icon',{on:{"click":function($event){return _vm.limpaProva(1)}}},[_vm._v("mdi-reload")]),_c('v-radio-group',{attrs:{"name":"prova1","column":""},model:{value:(_vm.prova1),callback:function ($$v) {_vm.prova1=$$v},expression:"prova1"}},[_c('v-radio',{attrs:{"label":"Grand Slam","color":"red","value":"1"}}),_c('v-radio',{attrs:{"label":"Winner 1000","color":"red","value":"2"}}),_c('v-radio',{attrs:{"label":"Winner 500","color":"red","value":"3"}}),_c('v-radio',{attrs:{"label":"Winner 250","color":"red","value":"4"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('strong',[_vm._v("SÊNIOR")]),_vm._v(" "),_c('v-icon',{on:{"click":function($event){return _vm.limpaProva(2)}}},[_vm._v("mdi-reload")]),_c('v-radio-group',{attrs:{"name":"prova2","column":""},model:{value:(_vm.prova2),callback:function ($$v) {_vm.prova2=$$v},expression:"prova2"}},[_c('v-radio',{attrs:{"label":"Sênior A","color":"red","value":"1"}}),_c('v-radio',{attrs:{"label":"Sênior B","color":"red","value":"2"}}),_c('v-radio',{attrs:{"label":"Sênior C","color":"red","value":"3"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }